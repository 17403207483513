import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ExternalLink } from '../../theme'

const StyledMenu = styled.div`
  /* margin-left: 0.5rem; */
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border: none;
  text-align: left;
  white-space: nowrap;
`

const MenuFlyout = styled.span`
  min-width: 15rem;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 4px;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  right: -2rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 2rem;
    left: 0;
  `};
`

const MenuItem = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 1.5rem;
  font-family: nasalization;
  color: ${({ theme }) => theme.text2};
  :hover {
    background-color: #f8f9fa;
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
  :first-child {
    margin-top: 0.5rem;
  }
  :last-child {
    margin-bottom: 0.5rem;
  }
`

const CruSpan = styled.span`
  font-family: nasalization;
  color: rgba(255,255,255, 0.5);
  cursor: pointer;
  margin-right: 50px;
  &:hover {
    color: #eb761c;
  }
`

export default function TestNet() {
  const { t } = useTranslation()
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.TEST_NET)
  const toggle = useToggleModal(ApplicationModal.TEST_NET)
  useOnClickOutside(node, open ? toggle : undefined)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <CruSpan onClick={toggle}>{t('testNet')}</CruSpan>
      {open && (
        <MenuFlyout>
          <MenuItem id="link" href={t('previewNetworkLink')}>
            {t('joinPreviewNetwork')}
          </MenuItem>
          <MenuItem id="link" href="https://crust.subscan.io">
            {t('blockchainBrowser')}
          </MenuItem>
          <MenuItem id="link" href="https://splorer.crust.network">
            {t('storageExplorer')}
          </MenuItem>
          <MenuItem id="link" href="https://apps.crust.network/">
            {t('crustApps')}
          </MenuItem>
          <MenuItem id="link" href="https://crust.network/#product">
            {t('crustCloud')}
          </MenuItem>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
