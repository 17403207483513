import { darken } from 'polished'
import React, { useRef, useState } from 'react'
import { Menu as MenuIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import Documents from '../Documents'
import Row, { RowFixed } from '../Row'
import Settings from '../Settings'
import TestNet from '../TestNet'
import Web3Status from '../Web3Status'
import Language from './Language'


const HeaderFrame = styled.div`
display: grid;
grid-template-columns: 1fr;
align-items: center;
justify-content: space-between;
align-items: center;
flex-direction: row;
width: 100%;
top: 0;
position: relative;
border-bottom: 1px solid #333333;
padding: 0 14%;
z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: black;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`
const HomeHeaderElement = styled.div.attrs(({ midShow }: { midShow: boolean }) => ({ midShow }))`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
  padding: 0.6rem  20px;
  white-space: nowrap;
  ${({ theme, midShow }) => theme.mediaWidth.upToMedium`
    display: ${midShow ? 'flex' : 'none'};
    top: 3rem;
    left: 0;
    flex-direction: column;
    height: 14rem;
    justify-content: space-between;
    position: absolute;
    padding: 0.5rem 1.5rem;
    align-items: flex-start;
  `};
`

const HeaderRow = styled(RowFixed)`
  background-color: ${({ theme }) => theme.black};
  width: 100%;
`

const HeaderLinks = styled(Row)`
  width: 100%;
  justify-content: center;
  bottom: 1px solid #333333;
  flex-shrink: 1;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-left: 1rem;
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.black};
  `}
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: none;
  }
`

const CruSpan = styled.span`
  color: rgba(255,255,255, 0.5);
  font-family: nasalization;
  white-space: nowrap;
  padding: 0.5rem 0;
  cursor: pointer;
  margin-right: 50px;
  &:hover {
    color: #eb761c;
  }
`

const CruLogoContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  word-break: keep-all;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  font-family: nasalization;
  border-bottom: 1px solid transparent !important;
  &.${activeClassName} {
    /* font-weight: 600; */
    border-bottom: 1px solid ${({ theme }) => theme.yellow2} !important;
    color: ${({ theme }) => theme.yellow2};
  }

  :hover {
    color: ${({ theme }) => darken(0.1, theme.yellow2)};
  }
  height: 54px;
  line-height: 54px;
  padding: 0 20px;
  margin: 0 3rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 0;
    border-bottom: unset;
    padding: 0 12px;
  `}
`
const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`
const MenuIconWarp = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `};
`
const PlaceSpace = styled.div`
  height: 0rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: 14rem;
  `}
`

export default function Header() {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const [showMoreLinks, setShowMoreLinks] = useState(false)
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, showMoreLinks ? () => setShowMoreLinks(false) : undefined)
  return (
    <HeaderFrame id="header-frame" ref={node as any}>
      {/* <ClaimModal /> */}
      {/* <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal> */}

      <HeaderRow>
        <CruLogoContainer>
          <img src="https://crust-data.oss-cn-shanghai.aliyuncs.com/crust-home/assets/images/logo.png" alt="logo" />
        </CruLogoContainer>
        <HomeHeaderElement midShow={showMoreLinks}>
          <CruSpan onClick={() => window.open('https://crust.network/', '_blank')}>{t('home')}</CruSpan>
          <TestNet />
          <Documents />
          <CruSpan onClick={() => window.open('https://crust.network/faq', '_blank')}>{t('faq')}</CruSpan>
          <Language />
        </HomeHeaderElement>
        <HeaderControls>
          <HeaderElement>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              <Web3Status />
            </AccountElement>
          </HeaderElement>

          <HeaderElementWrap>
            <Settings />
          </HeaderElementWrap>
        </HeaderControls>
      </HeaderRow>
      <HeaderLinks>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}> {t('swap')} </StyledNavLink>
        <StyledNavLink id={`pool-nav-link`} to={'/cru/step1'}> {t('pool')} </StyledNavLink>
        <StyledNavLink id={`csm-nav-link`} to={'/cru/step2'} isActive={(match, { pathname }) =>
          Boolean(match) ||
          pathname === ('/cru/step3')
        }> {t('csm')} </StyledNavLink>
        <StyledNavLink id={`info-nav-link`} to={'/cru/info'}> {t('info')} </StyledNavLink>
        <MenuIconWarp>
          <MenuIcon
            size="20"
            color="white"
            onClick={() => {
              setShowMoreLinks(!showMoreLinks)
            }}>
          </MenuIcon>
        </MenuIconWarp>
      </HeaderLinks>
      { showMoreLinks && <PlaceSpace />}
    </HeaderFrame>
  )
}
